import { formatUnits } from "ethers";
import { useQueries } from "@tanstack/react-query";
import * as constants from "@/constants";
import { getCumulativeMev } from "@/api";

import { useValidatorsQuery } from "./validator";

/**
 * Fetches the cumulative MEV data for all validators.
 */
export function useMainChartData() {
  const {
    MAIN_CHART_DATAPOINT_LIMIT: POINT_LIMIT,
    MAIN_CHART_DATAPOINT_EVERY: POINT_INTERVAL,
    PROBABILITY_THRESHOLD,
  } = constants;

  const { data: validators = [] } = useValidatorsQuery({
    select: (arr = []) => arr.filter((validator) => validator.stake !== "0"),
  });

  return useQueries({
    queries: validators.map((validator) => ({
      queryKey: ["USE_MAIN_CHART_DATA", validator.pubkey],
      queryFn: async () => {
        const datapoints = await getCumulativeMev({
          proposer: validator.pubkey,
          limit: POINT_LIMIT,
          every: POINT_INTERVAL,
          probabilityThreshold: PROBABILITY_THRESHOLD,
        });

        const parsedDatapoints = datapoints.map(({ value }) =>
          parseFloat(formatUnits(value.toFixed(0), 6)),
        );

        return {
          validator: validator.moniker,
          datapoints: parsedDatapoints,
        };
      },
    })),
    combine: (queries) => {
      const results = queries.flatMap(({ data }) => data ?? []);

      const data = Array.from(
        { length: Math.floor(POINT_LIMIT / POINT_INTERVAL) },
        (_, i) => {
          const point: Record<string, number> = {
            key: (i + 1) * POINT_INTERVAL,
          };

          for (const { validator, datapoints } of results) {
            if (i in datapoints) {
              point[validator] = datapoints[i];
            }
          }

          return point;
        },
      );

      return { data };
    },
  });
}
