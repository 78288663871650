import type { ComponentProps } from "react";
import { SpinnerIcon } from "@/components/icons/Spinner";
import { useIsFetching } from "@tanstack/react-query";

export const MainChartLoadingIndicator = (props: ComponentProps<"div">) => {
  const isChartLoading = useIsFetching({
    queryKey: ["USE_MAIN_CHART_DATA"],
  });

  if (!isChartLoading) return null;

  return (
    <div role="status" {...props}>
      <SpinnerIcon />
      <span className="sr-only">Loading chart...</span>
    </div>
  );
};
